<template>
  <main>
    <div class="form status-form quest">
      <div class="breadcumb">

      </div>
      <Form
        id="form"
        :form_id="form_id"
        :uid="uid"
        :submission_id="submission_id"
        :server="server"
        v-on:results="getResult"
        v-on:form_submit_loading="loading"
      />
      <div class="captcha">
        This site is protected by reCAPTCHA and the Google<br />
        <a href="https://policies.google.com/privacy" target="_blank"
          >Privacy Policy</a
        >
        and
        <a href="https://policies.google.com/terms" target="_blank"
          >Terms of Service</a
        >
        apply.
      </div>
    </div>
  </main>
</template>
<script>
let consts = require("../config");
import axios from "axios";
export default {
  name: "Questionnaire",
  data: function() {
    return {
      submission_id: this.$route.params._id,
      form_id: '606c79235491780015e899aa',
      server: "https://api.lucien.ai",
      uid: this.$route.params._secu,
    };
  },
  async mounted() {

    try {
      let { data } = await axios.get(
        "https://api.lucien.ai/api/v2/submissions/" +
          this.$route.params._id +
          "?uid=" +
          this.$route.params._secu
      );

     
      if (data.question_1) {
          this.$router.push(
            "/status/" + this.$route.params._id + "/" + this.$route.params._secu
          );
      }
    } catch(error) {
       console.log(error)
    }
  },
  methods: {
    loading(value) {
      if (value === true) {
        this.$swal({
          title: "Envoi en cours",
          html: "Merci de bien vouloir patienter ...",
          timerProgressBar: true,
          onBeforeOpen: () => {
            this.$swal.showLoading();
          },
        });
      }
    },
    getResult(result) {
      if (result._id) {
        this.$swal({
          type: "success",
          confirmButtonText: "OK",
          confirmButtonColor: "#C94330",
          html:
            "<b>Merci d'avoir répondu à notre questionnaire.</b>",
        }).then((result) => {
          this.$router.push(
            "/status/" + this.$route.params._id + "/" + this.$route.params._secu
          );
        });
      } else {
        this.$swal({
          type: "error",
          confirmButtonText: "OK",
          confirmButtonColor: "#C94330",
          text: "Une erreur est survenue",
        });
      }
    },
  },
};
</script>
